export const FilterIconSvg = ({ onFilterToggle, filterIconColor }) => {
  return (
    <svg
      onClick={onFilterToggle}
      className="filter-icon"
      width="97"
      height="97"
      viewBox="0 0 97 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="9.5"
        y1="23.5"
        x2="17.5"
        y2="23.5"
        stroke={filterIconColor}
        strokeWidth="7"
        strokeLinecap="round"
      />
      <line
        x1="47.5"
        y1="23.5"
        x2="85.5"
        y2="23.5"
        stroke={filterIconColor}
        strokeWidth="7"
        strokeLinecap="round"
      />
      <circle
        cx="27"
        cy="23"
        r="7.5"
        stroke={filterIconColor}
        strokeWidth="5"
      />
      <line
        x1="85.5"
        y1="48.5"
        x2="82.5"
        y2="48.5"
        stroke={filterIconColor}
        strokeWidth="7"
        strokeLinecap="round"
      />
      <line
        x1="54.5"
        y1="48.5"
        x2="9.5"
        y2="48.5"
        stroke={filterIconColor}
        strokeWidth="7"
        strokeLinecap="round"
      />
      <circle
        cx="74"
        cy="49"
        r="7.5"
        transform="rotate(-180 74 49)"
        stroke={filterIconColor}
        strokeWidth="5"
      />
      <line
        x1="9.5"
        y1="75.5"
        x2="33.5"
        y2="75.5"
        stroke={filterIconColor}
        strokeWidth="7"
        strokeLinecap="round"
      />
      <line
        x1="61.5"
        y1="75.5"
        x2="85.5"
        y2="75.5"
        stroke={filterIconColor}
        strokeWidth="7"
        strokeLinecap="round"
      />
      <circle
        cx="42"
        cy="75"
        r="7.5"
        stroke={filterIconColor}
        strokeWidth="5"
      />
    </svg>
  );
};
